import React, { useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import {
  withStyles, Snackbar,
  makeStyles, Container, Grid, TextField, Button, FormControlLabel, Checkbox
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useForm, Controller } from 'react-hook-form'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import MailIcon from '@material-ui/icons/MailOutline'
import ComputerIcon from '@material-ui/icons/ComputerOutlined'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const defaultValues = {
  name: "",
  telephone: "",
  email: "",
  newsletter: true
}

export default function Home() {

  const [open, setOpen] = useState(false)
  const [state, setState] = useState({
    newsletter: true,
  })

  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('')

  const snackbar = (message, severity) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)
  }

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const methods = useForm()
  const { handleSubmit, control, reset } = methods
  const onSubmit = data => {
    fetch("https://us-central1-ediana-andrade.cloudfunctions.net/api/diagnostic", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        console.log('res', res)
        return res.json()
      })
      .then(resData => {
        console.log('resData', resData)
        if (resData.errors) {
          if (resData.errors[0].status === 422) {
            if (resData.errors[0].data[0].message === 'Name cannot be null.') {
              snackbar('Preencha o campo Nome.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'Email is invalid.') {
              snackbar('Preencha o campo Email com um email válido.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'Email cannot be null.') {
              snackbar('Preencha o campo Email.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'Telephone cannot be null.') {
              snackbar('Preencha o campo WhatsApp / Telefone.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'Message cannot be null.') {
              snackbar('Preencha o campo Mensagem.', 'error')
            }
            else {
              snackbar(resData.errors[0].data[0].message, 'error')
            }
          }
        }
        else {
          snackbar('Formulário enviado com sucesso!', 'success')
          reset({ defaultValues })
        }
      })
      .catch(err => {
        snackbar('Erro ao enviar formulário, verifique todos os campos e tente novamente.', 'error')
      })
  };

  const useStyles = makeStyles(theme => ({
    '@global': {
      footer: {
        width: '100%',
        backgroundColor: '#18273e',
        borderTop: 'solid 2px #FFA65D',
        color: '#FFA65D',
        fontSize: '1.1em',
        margin: '0px',
        padding: '0px',
      },
    },
    Button: {
      backgroundColor: `#FF9F00`,
      color: '#FFF',
      fontFamily: 'Varela Round',
      borderRadius: '30px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      margin: '10px',
      fontSize: '15px',
      '&:hover': {
        backgroundColor: `#FF9F00`
      },
    },
    logo: {
      width: '60%',
      [theme.breakpoints.up('md')]: {
        width: '200px'
      },
    },
    title: {
      textAlign: 'center',
      marginTop: '10px'
    },
    center: {
      textAlign: 'center'
    },
    right: {
      textAlign: 'right'
    },
    l1: {
      textTransform: 'uppercase',
      display: 'block',
      fontSize: '2em',
      [theme.breakpoints.up('sm')]: {
        textTransform: 'uppercase',
        display: 'block',
        fontSize: '2.2em'
      },
    },
    l2: {
      fontSize: '2.6em',
      textTransform: 'uppercase',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.5em',
        textTransform: 'uppercase',
        display: 'block'
      },
    },
    l3: {
      display: 'block',
      fontSize: '1.8em',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        fontSize: '2.2em'
      },
    },
    whiteBox: {
      backgroundColor: '#FFF',
      color: '#000',
      borderRadius: '10px',
      boxShadow: '5px 5px 10px #000',
      display: 'table'
    },
    whiteBoxPadding: {
      paddingTop: '5px',
      paddingLeft: '35px',
      paddingRight: '35px',
      paddingBottom: '10px'
    },
    image: {
      width: '80%'
    },
    style1: {
      color: '#FFA65D',
      lineHeight: '150%'
    },
    style2: {
      color: '#061937',
      lineHeight: '150%'
    },
    width: {
      width: '100%',
    },
    icon: {
      marginLeft: '10px',
      marginRight: '10px',
      fontSize: '1.3em',
      color: '#FFA65D',
      '&:hover': {
        color: '#FFA65D'
      },
    },
  }));

  const classes = useStyles();

  const { newsletter } = state

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: `#061937`
      },
      '& label': {
        fontFamily: 'Varela Round',
        fontSize: '15px'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `#061937`
      },
      '& .MuiInputBase-input': {
        fontFamily: 'Varela Round',
        fontSize: '15px'
      },
      '& .MuiButton-root': {
        fontFamily: 'Varela Round',
        fontSize: '15px',
        color: 'red'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `#061937`,
        },
        '&:hover fieldset': {
          borderColor: `#061937`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `#061937`,
        }
      },
    },
  })(TextField)

  const CssFormControlLabel = withStyles({
    root: {
      '& .MuiTypography-body1': {
        fontFamily: 'Varela Round',
        fontSize: '15px'
      }
    },
  })(FormControlLabel)

  return (
    <>
      <GatsbySeo
        title='Ediana Andrade - Diagnóstico Empresarial Gratuito'
        description='Peça já o seu Diagnóstico Empresarial Gratuito na Ediana Andrade e inove a sua empresa'
        canonical='https://diagnostico.edianaandrade.com.br'
        noindex={false}
        nofollow={false}
        openGraph={{
          url: `https://diagnostico.edianaandrade.com.br`,
          title: `Ediana Andrade - Diagnóstico Empresarial Gratuito`,
          description: 'Peça já o seu Diagnóstico Empresarial Gratuito na Ediana Andrade e inove a sua empresa',
          images: [
            {
              url: `/logo2.png`,
              alt: `Ediana Andrade - Diagnóstico Empresarial Gratuito`
            }
          ],
          site_name: `Ediana Andrade - Diagnóstico Empresarial Gratuito`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.title}>
            <h1><img className={classes.logo} src="/logo2.png" title="Ediana Andrade - Diagnóstico Empresarial Gratuito" alt="Ediana Andrade - Diagnóstico Empresarial Gratuito" /></h1>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <p>
              <span className={classes.l1}>Diagnóstico</span>
              <span className={classes.l2}>Comercial<br />Gratuito</span>
              <span className={classes.l3}>Melhore a performance de vendas
              da sua equipe e alcance os resultados
              desejados.
              </span>
            </p>
            <p>
              <img src="/image.png" alt="Ediana Andrade - Diagnóstico Empresarial Gratuito" title="Ediana Andrade - Diagnóstico Empresarial Gratuito" className={classes.image} />
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={classes.whiteBox}>
              <div className={classes.whiteBoxPadding}>
                <div className={classes.style1}>
                  <p>
                    Através do Diagnóstico Comercial você terá uma visão clara da situação atual da sua equipe comercial e do relacionamento com seus clientes, quais são os KPIs do seu negócio e receber estratégias para um planejamento que faça seus resultados crescerem.
              </p>
                </div>
                <div className={classes.style2}>
                  <p>
                    Deixe deus dados no formulário abaixo e entrarei em contato para agendarmos seu diagnóstico comercial
              </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller as={CssTextField} name="name" control={control} label="Nome" className={classes.width} />
                  <Controller as={CssTextField} name="telephone" control={control} label="WhatsApp ou Telefone" className={classes.width} />
                  <Controller as={CssTextField} name="email" control={control} label="Email" className={classes.width} />
                  <Controller
                    as={<CssFormControlLabel
                      onChange={handleChange('newsletter')} checked={newsletter}
                      control={<Checkbox color="primary" name="newsletter" style={{ color: `#061937` }} />}
                      label="Desejo receber dicas e novidades"
                      labelPlacement="end"
                    />}
                    name="newsletter"
                    value="newsletter"
                    control={control}
                    defaultValue={true}
                  />
                  <br />
                  <div className={classes.center}>
                    <Button className={classes.Button} type="submit">
                      Quero marcar meu diagnóstico
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container >
      <footer>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <p>
                Desenvolvendo habilidades, <br />alcançando resultados
              </p>
            </Grid>
            <Grid item xs={6} className={classes.right}>
              <p>
                <a href="https://api.whatsapp.com/send?phone=5533998246388" target="_blank" rel="noreferrer">
                  <WhatsAppIcon className={classes.icon} /></a>
                <a href="https://www.instagram.com/edianaandradementora/" target="_blank" rel="noreferrer">
                  <InstagramIcon className={classes.icon} /></a>
                <a href="https://www.facebook.com/edianaandradementora/" target="_blank" rel="noreferrer">
                  <FacebookIcon className={classes.icon} /></a>
                <a href="https://www.linkedin.com/in/ediana-andrade-996bb0125/" target="_blank" rel="noreferrer">
                  <LinkedInIcon className={classes.icon} /></a>
                <a href="mailto:contato@edianaandrade.com.br" target="_blank" rel="noreferrer">
                  <MailIcon className={classes.icon} /></a>
                <a href="https://edianaandrade.com.br/" target="_blank" rel="noreferrer">
                  <ComputerIcon className={classes.icon} /></a>
              </p>
            </Grid>
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        </Container>
      </footer>
    </>
  )
}
